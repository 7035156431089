<template>
  <div id="folder-details"></div>
</template>

<script>
</script>

<style scoped>
#folder-details {
  display: none;
}
</style>
